import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { DivisionledPageComponent } from './components/divisionled-page/divisionled-page.component';
import { ProductoledDetalleComponent } from './components/divisionled-page/productoled-detalle/productoled-detalle.component';
import { DivisionspeedlimitPageComponent } from './components/divisionspeedlimit-page/divisionspeedlimit-page.component';
import { ProductolimitDetalleComponent } from './components/divisionspeedlimit-page/productolimit-detalle/productolimit-detalle.component';
import { DivisionsolarPageComponent } from './components/divisionsolar-page/divisionsolar-page.component';
import { ProductosolarDetallesComponent } from './components/divisionsolar-page/productosolar-detalles/productosolar-detalles.component';
import { ContactoComponent } from './components/contacto/contacto.component';



const routes: Routes = [
  { path: '', component: HomePageComponent},
  { path: 'division-led', component: DivisionledPageComponent},
  { path: 'productoled/:index', component: ProductoledDetalleComponent},
  { path: 'division-speedlimit', component: DivisionspeedlimitPageComponent },
  { path: 'producto-speedlimit/:index', component: ProductolimitDetalleComponent},
  { path: 'division-solar', component: DivisionsolarPageComponent},
  { path: 'producto-solar/:index', component: ProductosolarDetallesComponent},
  { path: 'contacto', component: ContactoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
