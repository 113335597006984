import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productosolar-detalles',
  templateUrl: './productosolar-detalles.component.html',
  styleUrls: ['./productosolar-detalles.component.css']
})
export class ProductosolarDetallesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
