import { Component, OnInit } from '@angular/core';
import { ImagenSlider } from '../../../models/ImagenSlider';

// DECLARACION DE INTERFACE Y CREACIÓN DE INSTANCIAS

const PARQUE_SOLAR: ImagenSlider = {
  imgSrc: '../../../assets/img/parque-solar.jpg',
  ruta: 'division-solar',
  mainText: 'Parques Solares',
  secondaryText: 'La energía generada por nuestros parques solares es inyectada directamente a la red eléctrica local que permite disminuir la saturación de las redes y abastecer la demanda energética del área de influencia'
};

const SPEEDLIMIT: ImagenSlider = {
  imgSrc: '../../../assets/img/speed-limit.jpg',
  ruta: 'division-speedlimit',
  mainText: 'Speed Limit',
  secondaryText: 'Vehículos con equipamiento comercial. Contános tu idea y te la realizamos!'
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // ARRAY DE IMAGENES PARA SLIDER
  slider: ImagenSlider[] = [PARQUE_SOLAR, SPEEDLIMIT];

  constructor() {
  }

  ngOnInit() {
  }

}
