import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/home-page/header/header.component';
import { SectionsComponent } from './components/home-page/sections/sections.component';
import { DivisionledPageComponent } from './components/divisionled-page/divisionled-page.component';
import { ProductoledDetalleComponent } from './components/divisionled-page/productoled-detalle/productoled-detalle.component';
import { ProductoledCardsComponent } from './components/divisionled-page/productoled-cards/productoled-cards.component';
import { DivisionspeedlimitPageComponent } from './components/divisionspeedlimit-page/divisionspeedlimit-page.component';
import { ProductolimitDetalleComponent } from './components/divisionspeedlimit-page/productolimit-detalle/productolimit-detalle.component';
import { ProductosledService } from './services/productos-led.service';
import { ProductoSpeedLimitService } from './services/producto-limit.service';
import { ProductospeedlimitCardsComponent } from './components/divisionspeedlimit-page/productospeedlimit-cards/productospeedlimit-cards.component';
import { ProductolimitelectricoCardsComponent } from './components/divisionspeedlimit-page/productolimitelectrico-cards/productolimitelectrico-cards.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { DivisionsolarPageComponent } from './components/divisionsolar-page/divisionsolar-page.component';
import { ProductosolarDetallesComponent } from './components/divisionsolar-page/productosolar-detalles/productosolar-detalles.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    SectionsComponent,
    DivisionledPageComponent,
    ProductoledDetalleComponent,
    ProductoledCardsComponent,
    DivisionspeedlimitPageComponent,
    ProductolimitDetalleComponent,
    ProductospeedlimitCardsComponent,
    ProductolimitelectricoCardsComponent,
    DivisionsolarPageComponent,
    ProductosolarDetallesComponent,
    ContactoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [ProductosledService, ProductoSpeedLimitService],
  bootstrap: [AppComponent]
})
export class AppModule { }
