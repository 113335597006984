import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-divisionspeedlimit-page',
  templateUrl: './divisionspeedlimit-page.component.html',
  styleUrls: ['./divisionspeedlimit-page.component.css']
})
export class DivisionspeedlimitPageComponent implements OnInit {

  constructor(private animateScrollService: NgAnimateScrollService,private dataService: DataService) { }

  ngOnInit() {
    this.dataService.logo="../../../assets/img/speedlimit-logo1.png";
    this.animateScrollService.scrollToElement('top', 1);
  }

}
