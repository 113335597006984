import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-divisionsolar-page',
  templateUrl: './divisionsolar-page.component.html',
  styleUrls: ['./divisionsolar-page.component.css']
})
export class DivisionsolarPageComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.logo= "../../../assets/img/logo2.png";
  }

}
