import { Component, OnInit } from '@angular/core';
import { ProductoLed } from 'src/app/models/ProductoLed';
import { ProductosledService } from '../../../services/productos-led.service';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-productoled-cards',
  templateUrl: './productoled-cards.component.html',
  styleUrls: ['./productoled-cards.component.css']
})
export class ProductoledCardsComponent implements OnInit {

  productos:ProductoLed[] = [];

  constructor(private productoledService: ProductosledService, private animateScrollService: NgAnimateScrollService) { }

  ngOnInit() {
    this.productos = this.productoledService.getProductos();
    this.animateScrollService.scrollToElement('top', 2);
  }

}
