import { Injectable } from '@angular/core';
import { ProductoSpeedLimit, ProductoSpeedLimitElectrico } from '../models/ProductoSpeedLimit';

@Injectable({
  providedIn: 'root'
})

export class ProductoSpeedLimitService {

   productos: ProductoSpeedLimit[] = [
     {
      nombre: 'SPEED LIMIT SL 500 CABINA',
      img: '../../../assets/img/speedlimit/speedlimitsl500cabina.png',
      medida: '1.25 mt. x 1.70 mt.',
      capacidad: '500kg',
      motor : '300 CC. Refrigerado por agua con radiador y electro ventilador automático',
      equipamiento: [
        'Arranque eléctrico',
        'Doble paquete de elásticos',
        'Diferencial flotante',
        'Freno de mano, caja de 5 marchas y marcha atrás con sirena',
        'Suspensión delantera doble amortiguador hidráulico y resorte',
        'Caja de herramientas, barandas rebatibles laterales y trasera',
        'Cabina de plástico ABS',
        'Parabrisas de vidrio',
        'Limpiaparabrisas',
        'Vi faro',
        'Estéreo'
      ],
      pdf: '../../../assets/pdf/speedlimit/speedlimitsl500cabina.pdf'
    },
    {
      nombre: 'SPEED LIMIT SL 500',
      img: '../../../assets/img/speedlimit/speedlimitsl500.png',
      medida: '1.25 mt. x 1.70 mt.',
      capacidad: '500kg',
      motor : '300 CC. Refrigerado por agua con radiador y electro ventilador automático',
      equipamiento: [
        'Arranque eléctrico',
        'Doble paquete de elásticos',
        'Diferencial flotante',
        'Freno de mano, caja de 5 marchas y marcha atrás con sirena',
        'Suspensión delantera doble amortiguador hidráulico y resorte',
        'Caja de herramientas, barandas rebatibles laterales y trasera',
        'Barandas rebatibles laterales y trasera'
      ],
      pdf: '../../../assets/pdf/speedlimit/speedlimitsl500.pdf'
    },
    {
      nombre: 'SPEED LIMIT SP 1000 CABINA',
      img: '../../../assets/img/speedlimit/speedlimitsp1000cabina.png',
      medida: '1.30 mt. x 2.40 mt.',
      capacidad: '1000kg',
      motor : '300 CC. Refrigerado por agua con radiador y electro ventilador automático',
      equipamiento: [
        'Arranque eléctrico',
        'Doble paquete de elásticos',
        'Diferencial flotante',
        'Freno de mano',
        'Caja de 5 marchas y marcha atrás con sirena',
        'Suspensión delantera doble',
        'Amortiguador hidráulico y resorte',
        'Caja de herramientas',
        'Barandas rebatibles laterales y trasera',
        'Cabina de plástico ABS, parabrisas de vidrio',
        'Limpiaparabrisas',
        'Vi faro',
        'Estéreo'
      ],
      pdf: '../../../assets/pdf/speedlimit/speedlimitsp1000cabina.pdf'
    },
    {
      nombre: 'SPEED LIMIT SP 1000',
      img: '../../../assets/img/speedlimit/speedlimitsp1000.png',
      medida: '1.30 mt. x 2.40 mt.',
      capacidad: '1000kg',
      motor : '300 CC. Refrigerado por agua con radiador y electro ventilador automático',
      equipamiento: [
        'Arranque eléctrico',
        'Doble paquete de elásticos',
        'Diferencial flotante',
        'Freno de mano',
        'Caja de 5 marchas y marcha atrás con sirena',
        'Suspensión delantera doble',
        'Amortiguador hidráulico y resorte',
        'Caja de herramientas',
        'Barandas rebatibles laterales y trasera',
      ],
      pdf: '../../../assets/pdf/speedlimit/speedlimitsp1000.pdf'
    },
    {
      nombre: 'FURGON SEMI TERMICO',
      img: '../../../assets/img/speedlimit/furgonsemitermico.png',
      medida: '2.34 mt. x 1.32 mt. x 1.37 mt.',
      capacidad: '1000kg / 4231.60 LITROS',
      motor : '300 CC. Refrigerado por agua con radiador y electro ventilador automático',
      equipamiento: [
        'Doble paquete de elásticos',
        'Diferencial flotante',
        'Caja de 5 marchas y marcha atrás',
        'Cabina plástica con parabrisas de vidrio',
        'Vi faro',
        'Limpia parabrisas',
        'Estéreo',
      ],
      pdf: '../../../assets/pdf/speedlimit/furgonsemitermico.pdf'
    },
];

  productoselectrico: ProductoSpeedLimitElectrico[] = [
    {
      nombre: 'SPEED LIMIT SP CAR',
      img: [
        '../../../assets/img/speedlimit/speedlimitcard.png',
      ],
      equipamiento: [
        'Motor brushless eléctrico de 60v',
        'Suspensión hidráulica con espirales',
        'Chasis de tubo',
        'Conformado con cabina plástica',
        'Transmisión automática directa, neutro y marcha atrás',
        'Parabrisas y luneta de vidrio',
        'Limpia parabrisas',
        'Cuatro puertas con ventanilla y seguro individual',
        'Estéreo con radio FM/AM entrada USB entrada auxiliar',
        'Ranura para tarjeta SD',
        'Corte de corriente general'
      ],
      autonomia: 'Carga completa 40 KM a velocidad promedio de 30 km/h, 32km a velocidad max.',
      tiempo: '4Hs.',
      pdf: '../../../assets/pdf/speedlimit/speedlimitcar.pdf'
    }
  ];

  constructor() { }

  public getProductos() {
    return this.productos;
  }

  public getProducto(index: number) {
    return this.productos[index];
  }

  public getProductosElectrico() {
    return this.productoselectrico;
  }

  public getProductoElectrico(index: number) {
    return this.productoselectrico[index];
  }
}

