import { Component, OnInit } from '@angular/core';
import { ProductoSpeedLimit } from '../../../models/ProductoSpeedLimit';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { ProductoSpeedLimitService } from 'src/app/services/producto-limit.service';

@Component({
  selector: 'app-productospeedlimit-cards',
  templateUrl: './productospeedlimit-cards.component.html',
  styleUrls: ['./productospeedlimit-cards.component.css']
})
export class ProductospeedlimitCardsComponent implements OnInit {

  productos: ProductoSpeedLimit [] = [];
  constructor( private productoSpeedLimitService: ProductoSpeedLimitService, private animateScrollService: NgAnimateScrollService) { }

  ngOnInit() {
    this.productos = this.productoSpeedLimitService.getProductos();
  }

}
