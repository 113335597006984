import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductoLed } from '../../../models/ProductoLed';
import { ProductosledService } from 'src/app/services/productos-led.service';

@Component({
  selector: 'app-productoled-detalle',
  templateUrl: './productoled-detalle.component.html',
  styleUrls: ['./productoled-detalle.component.css']
})
export class ProductoledDetalleComponent implements OnInit {

  producto: ProductoLed;

  constructor( private route: Router, private activateRoute: ActivatedRoute, private productosledService: ProductosledService ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.producto = this.productosledService.getProducto(params.index);
      console.log(this.producto.general[0]);
    });

  }

}
