import { Injectable } from '@angular/core';
import { ProductoLed } from '../models/ProductoLed';

@Injectable({
  providedIn: 'root'
})

export class ProductosledService {

   productos: ProductoLed[] = [
     {
      nombre: 'HM-GMW-60W',
      img: '../../../assets/img/led/hngmw60w.png',
      general: [
        'Cuerpo de inyección de aluminio.',
        'Tornillería de Acero Inoxidable 304.',
        'Alta perfomance de disipación térmica y alta resistencia a la corrosión.',
        'Sistema óptico de alta eficencia. Integración de sistema anti deslumbramiento.',
        'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
        'GRADO IP: 64',
        'Vida útil: 50000hs'],
      aplicacion: [
        'Calles principales de ciudades. Calles sub troncales',
        'Áreas comerciales e industriales',
        'Estadios de tamaño medio',
        'Autopistas y todo tipo de ruta',
        'Estación de trenes',
        'Áreas portuarias',
        'Iluminación General',
      ],
      pdf: '../../../assets/pdf/led/hngmw60w.pdf'
    },
    {
      nombre: 'LLL-UFO-60W',
      img: '../../../assets/img/led/lllufo60w.png',
      general: [
        'Cuerpo de inyección de aluminio.',
        'Tornillería de Acero Inoxidable 304.',
        'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
        'GRADO IP: 65',
        'Vida útil: 50000hs'],
      aplicacion: [
        'Plazas',
        'Paseos Públicos',
        'Jardines',
        'Iluminación General',
        'Ciclovías'
      ],
      pdf: '../../../assets/pdf/led/lllufo60w.pdf'
    },
    {
      nombre: 'HN-GMW-120W',
      img: '../../../assets/img/led/hngmw120w.png',
      general: [
        'Cuerpo de inyección de aluminio.',
        'Tornillería de Acero Inoxidable 304.',
        'Alta perfomance de disipación térmica y alta resistencia a la corrosión.',
        'Sistema óptico de alta eficencia. Integración de sistema anti deslumbramiento.',
        'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
        'GRADO IP: 65',
        'Vida útil: 50000hs'],
      aplicacion: [
        'Calles principales de ciudades. Calles sub troncales',
        'Áreas comerciales e industriales',
        'Estadios de tamaño medio',
        'Autopistas y todo tipo de ruta',
        'Estación de trenes',
        'Áreas portuarias',
        'Iluminación General',
      ],
      pdf: '../../../assets/pdf/led/hngmw120w.pdf'
    },
    {
    nombre: 'HW-GMW-120W',
    img: '../../../assets/img/led/hwgmw120w.png',
    general: [
      'Cuerpo de inyección de aluminio.',
      'Tornillería de Acero Inoxidable 304.',
      'Alta perfomance de disipación térmica y alta resistencia a la corrosión.',
      'Sistema óptico de alta eficencia. Integración de sistema anti deslumbramiento.',
      'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
      'GRADO IP: 65',
      'Vida útil: 50000hs'],
    aplicacion: [
      'Calles principales de ciudades. Calles sub troncales',
      'Áreas comerciales e industriales',
      'Estadios de tamaño medio',
      'Autopistas y todo tipo de ruta',
      'Estación de trenes',
      'Áreas portuarias',
      'Iluminación General',
    ],
    pdf: '../../../assets/pdf/led/hwgmw120w.pdf'
  },
  {
    nombre: 'HN-GMW-180W',
    img: '../../../assets/img/led/hngmw180w.png',
    general: [
      'Cuerpo de inyección de aluminio.',
      'Tornillería de Acero Inoxidable 304.',
      'Alta perfomance de disipación térmica y alta resistencia a la corrosión.',
      'Sistema óptico de alta eficencia. Integración de sistema anti deslumbramiento.',
      'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
      'GRADO IP: 65',
      'Vida útil: 50000hs'],
    aplicacion: [
      'Calles principales de ciudades. Calles sub troncales',
      'Áreas comerciales e industriales',
      'Estadios de tamaño medio',
      'Autopistas y todo tipo de ruta',
      'Estación de trenes',
      'Áreas portuarias',
      'Iluminación General',
    ],
    pdf: '../../../assets/pdf/led/hngmw180w.pdf'
  },
  {
    nombre: 'HN-GMW-240W',
    img: '../../../assets/img/led/hngmw240w.png',
    general: [
      'Cuerpo de inyección de aluminio.',
      'Tornillería de Acero Inoxidable 304.',
      'Alta perfomance de disipación térmica y alta resistencia a la corrosión.',
      'Sistema óptico de alta eficencia. Integración de sistema anti deslumbramiento.',
      'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
      'GRADO IP: 65',
      'Vida útil: 50000hs'],
    aplicacion: [
      'Calles principales de ciudades. Calles sub troncales',
      'Áreas comerciales e industriales',
      'Estadios de tamaño medio',
      'Autopistas y todo tipo de ruta',
      'Estación de trenes',
      'Áreas portuarias',
      'Iluminación General',
    ],
    pdf: '../../../assets/pdf/led/hngmw240w.pdf'
  },
  {
    nombre: 'LLLR300W',
    img: '../../../assets/img/led/lllr300w.png',
    general: [
      'Cuerpo de inyección de aluminio.',
      'Tornillería de Acero Inoxidable 304.',
      'Alta perfomance de disipación térmica y alta resistencia a la corrosión.',
      'Sistema óptico de alta eficencia. Integración de sistema anti deslumbramiento.',
      'Juntas de alta eficencia y duración, construidas en siliconas de alta calidad',
      'GRADO IP: 65',
      'Vida útil: 50000hs'],
    aplicacion: [
      'Calles principales de ciudades. Calles sub troncales',
      'Áreas comerciales e industriales',
      'Estadios de tamaño medio',
      'Autopistas y todo tipo de ruta',
      'Estación de trenes',
      'Áreas portuarias',
      'Iluminación General',
    ],
    pdf: '../../../assets/pdf/led/lllr300w.pdf'
  }
];

  constructor() { }

  public getProductos(){
    return this.productos;
  }

  public getProducto(index: number) {
    return this.productos[index];
  }


}
