import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Contacto } from 'src/app/models/Contacto';
import { ContactoService } from '../../services/email.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  formContacto: FormGroup;

  contacto: Contacto;

  error: {};

  default = 'REPUESTOS Y ACCESORIOS';

  constructor(
    private emailService: ContactoService,
    private router: Router
  ) {
    this.formContacto = new FormGroup(
      {
        asunto: new FormControl(),
        nombre: new FormControl('', Validators.required),
        apellido: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
        mensaje: new FormControl('', [Validators.required, Validators.maxLength(512)])
      }
    );
    this.formContacto.controls.asunto.setValue(this.default, { onlySelf: true });
  }

  ngOnInit() {
  }

  enviarMensaje() {

    switch (this.formContacto.value.asunto) {
      case 'VENTAS':
        this.emailService.contactForm(this.formContacto.value).subscribe(
          data => this.contacto = data,
          error => this.error = error,
        );
        break;
      case 'REPUESTOS Y ACCESORIOS':
        this.emailService.contactForm(this.formContacto.value).subscribe(
          data => this.contacto = data,
          error => this.error = error,
        );
        break;
      case 'OTRAS CONSULTAS':
        this.emailService.contactForm(this.formContacto.value).subscribe(
          data => this.contacto = data,
          error => this.error = error,
        );
        break;
      case 'INSTITUCIONAL':
        this.emailService.contactForm2(this.formContacto.value).subscribe(
          data => this.contacto = data,
          error => this.error = error,
        );
        break;
      default:
        break;
    }

    this.router.navigate(['']);

  }
}
