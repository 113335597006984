import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductoSpeedLimit } from 'src/app/models/ProductoSpeedLimit';
import { ProductoSpeedLimitService } from 'src/app/services/producto-limit.service';

@Component({
  selector: 'app-productolimit-detalle',
  templateUrl: './productolimit-detalle.component.html',
  styleUrls: ['./productolimit-detalle.component.css']
})
export class ProductolimitDetalleComponent implements OnInit {

  producto: ProductoSpeedLimit;

  constructor( private route: Router, private activateRoute: ActivatedRoute, private productosledService: ProductoSpeedLimitService ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.producto = this.productosledService.getProducto(params.index);
      console.log(this.producto.equipamiento[0]);
    });

  }

}
