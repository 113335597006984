import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  logo="../../../assets/img/logo2.png";

  constructor() { }
}
