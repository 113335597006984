import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-divisionled-page',
  templateUrl: './divisionled-page.component.html',
  styleUrls: ['./divisionled-page.component.css']
})
export class DivisionledPageComponent implements OnInit, AfterViewInit, AfterViewChecked {

  constructor( private animateScrollService: NgAnimateScrollService, private dataService: DataService) {

  }

  ngOnInit() {
    this.dataService.logo= "../../../assets/img/logo2.png";
  }

  ngAfterViewInit(){
    console.log('afterviewinit');
  }

  ngAfterViewChecked() {
    this.navitateToTop(2);
  }

  navitateToTop(duration:number){
  }

}
