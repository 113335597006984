import { Component, OnInit } from '@angular/core';
import { ProductoSpeedLimitElectrico } from 'src/app/models/ProductoSpeedLimit';
import { ProductoSpeedLimitService } from 'src/app/services/producto-limit.service';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-productolimitelectrico-cards',
  templateUrl: './productolimitelectrico-cards.component.html',
  styleUrls: ['./productolimitelectrico-cards.component.css']
})
export class ProductolimitelectricoCardsComponent implements OnInit {

  productos: ProductoSpeedLimitElectrico [] = [];

  constructor( private productoSpeedLimitService: ProductoSpeedLimitService, private animateScrollService: NgAnimateScrollService) { }

  ngOnInit() {
    this.productos = this.productoSpeedLimitService.getProductosElectrico();
  }

}
